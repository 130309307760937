import { useEffect, useState } from 'react';

const useDebounce = () => {
  const useDebounceStateValue = (value, delay = 300) => {
    const [debouncedValue, setDebouncedValue] = useState(value);
    useEffect(() => {
      const handler = setTimeout(() => {
        setDebouncedValue(value)
      }, delay);
  
      return () => {
        clearTimeout(handler);
      }
    }, [value, delay]);
  
    return debouncedValue;
  }

  const debounceFn = (fn, delay = 300) => {
    let timer;

    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => { fn.apply(this, args); }, delay);
    }
  }

  return { useDebounceStateValue, debounceFn };
}

export default useDebounce;
