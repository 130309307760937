import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import $ from "jquery";
import {
  updateState,
  selectMap,
  selectDitaPath,
  selectID,
  selectVersion,
  selectVersionByImport,
} from "../features/counter/counterSlice";
import {
  setOpenModalFlag,
  selectOpenModalFlag,
} from "../store/reducers/favourite";
import {
  selectSearchIndex,
  setSearchIndex,
  setSearchTerm,
} from "../store/reducers/search";
import useDebounce from "../hooks/useDebounce";
import FavouriteModal from "../components/favouriteModal";
import { NavLink } from "react-router-dom";
const DrugIndexModal = React.lazy(() => import("./drugIndexModal"));
const LoginButton = React.lazy(() => import("./loginButton"));
const Profile = React.lazy(() => import("./profile"));

const defiant = window.defiant;
const tgg = window.tgg;

const NavBar = (props) => {
  const dispatch = useDispatch();
  const inputFieldRef = useRef(null);
  const history = useHistory();
  const { push, setWithinSearchVal, setSynArray, isDXP } = props;
  const [guidelineOpenClass, setGuidelineOpenClass] = useState("");
  const [quicklinksOpenClass, setQuicklinksOpenClass] = useState("");
  const [mobileMenuClass, setMobileMenuClass] = useState("closed");
  const [navbarToggleClass, setNavbarToggleClass] = useState("collapsed");
  const [mobileSearchClass, setMobileSearchClass] = useState("closed");
  const [faClass, setFaClass] = useState("fa-bars");
  const [searchFaClass, setSearchFaClass] = useState("fa-search");
  const openModalFlag = useSelector(selectOpenModalFlag);
  const authID = useSelector(selectID);
  const searchIndex = useSelector(selectSearchIndex);
  const routerAction = useSelector((state) => state.router.action);
  const tocMap = useSelector(selectMap);
  const ditaPath = useSelector(selectDitaPath);
  const ccmsversion = useSelector(selectVersion);
  const version = ccmsversion.selected;
  const versionByImport = useSelector(selectVersionByImport);

  useEffect(() => {
    $(document).on("click", "#drugIndexModal .close", function (e) {
      $("#drugIndexModal").removeClass("in");
      $("#drugIndexModal").css("display", "none");
      $(".modal-backdrop").removeClass("in");
      $(".modal-backdrop").css("display", "none");
    });

    $(document).on("click", 'a[href="#drugIndexModal"]', function (e) {
      $("#drugIndexModal").addClass("in");
      $("#drugIndexModal").css("display", "block");
      $(".modal-backdrop").addClass("in");
      $(".modal-backdrop").css("display", "block");
    });

    window.onclick = function (event) {
      if (!event.target.matches(".dropbtn")) {
        setQuicklinksOpenClass("");
        setGuidelineOpenClass("");
      }
    };

    return () => {
      $(document).off("click", "#drugIndexModal .close");
      $(document).off("click", 'a[href="#drugIndexModal"]');
    };
  }, []);

  useEffect(() => {
    // console.log(routerAction);
    if (routerAction === "PUSH") {
      // console.log(mobileMenuClass,navbarToggleClass);
      if (mobileMenuClass === "open") {
        setMobileMenuClass("closed");
        setNavbarToggleClass("collapsed");
        setFaClass("fa-bars");
        $(".tg-mobile-menu").slideUp();
      }

      if ($("#drugIndexModal").is(":visible")) {
        $('#drugIndexModal [data-dismiss="modal"]').trigger("click");
        const data = { value: ["chapter"] };
        dispatch(updateState(data));
      }

      $("body").removeClass("modal-open");
    }
  }, [routerAction, tocMap]);

  useEffect(() => {
    if (window.location.href.indexOf("topicfile") === -1) {
      setTimeout(() => {
        $(".slider-arrow.show").trigger("click");
      }, 3000);
    }
  }, [ditaPath]);

  const quicklinksDropdown = (e) => {
    e.preventDefault();
    if (window.Microsoft) {
      if (quicklinksOpenClass === "open") {
        setQuicklinksOpenClass("open");
      } else {
        setQuicklinksOpenClass("");
      }
    } else {
      if (quicklinksOpenClass === "open") {
        setQuicklinksOpenClass("");
      } else {
        setQuicklinksOpenClass("open");
      }
    }
  };

  const guidelinesDropdown = (e) => {
    e.preventDefault();
    if (window.Microsoft) {
      if (guidelineOpenClass === "open") {
        setGuidelineOpenClass("open");
      } else {
        setGuidelineOpenClass("");
      }
    } else {
      if (guidelineOpenClass === "open") {
        setGuidelineOpenClass("");
      } else {
        setGuidelineOpenClass("open");
      }
    }
  };

  const toggleMobileMenu = (e) => {
    try {
      e.preventDefault();
    } catch (error) {} //TODO: remove try catch if this is not required.
    if (mobileMenuClass === "closed") {
      if (document.querySelector("a.slider-arrow.show")) {
        document.querySelector("a.slider-arrow.show").click();
      } else if (document.querySelector("div.slider-arrow.hide")) {
        document.querySelector("div.slider-arrow.hide").click();
      }

      setMobileMenuClass("open");
      setNavbarToggleClass("expanded");
      setFaClass("fa-times");
      $(".tg-mobile-menu").slideDown();

      setMobileSearchClass("closed");
      $(".tg-mobile-search").slideUp();
    } else {
      setMobileMenuClass("closed");
      setNavbarToggleClass("collapsed");
      setFaClass("fa-bars");
      $(".tg-mobile-menu").slideUp();
    }
  };

  const mobileSearchToggle = () => {
    if (mobileSearchClass === "closed") {
      setMobileSearchClass("opened");
      setSearchFaClass("fa-times");
      $(".tg-mobile-search").slideDown();

      setMobileMenuClass("closed");
      setNavbarToggleClass("collapsed");
      setFaClass("fa-bars");
      $(".tg-mobile-menu").slideUp();
    } else {
      setMobileSearchClass("closed");
      setSearchFaClass("fa-search");
      $(".tg-mobile-search").slideUp();
    }
  };

  const openLinks = (guidelineName) => {
    const ftxtcontenturl = `/viewTopic/?guidelinePage=${encodeURIComponent(
      guidelineName
    )}&etgAccess=true`;
    const guidelinePage = guidelineName.replace(/ /g, "_");
    const data = {
      ditaPath: `${guidelinePage}/`,
      queryString: {
        guidelinePage: encodeURIComponent(guidelineName),
        etgAccess: "true",
      },
      displayBanner: true,
    };
    dispatch(updateState(data));
    toggleMobileMenu();
    push(ftxtcontenturl);
  };

  const keypressSearchHandler = (event) => {
    // console.log(event);
    if (event.keyCode === 13 && event.target.value.length >= 3) {
      dispatch(setSearchTerm(event.target.value));
      // submitform(event.target.value, push);
      push(
        "/searchAction/?appendedinputbuttons=" +
          encodeURIComponent(event.target.value)
      );
      inputFieldRef.current.blur();

      if (setWithinSearchVal) {
        setWithinSearchVal("");
      }

      if (setSynArray) {
        setSynArray("");
      }
    }
  };

  const handleSearchChange = (e, dispatch, isMobile) => {
    dispatch(setSearchIndex(e.target.value));
    try {
      if (isMobile) {
        const mobileDatalist = document.getElementById("mobileDefiantList");
        const selectedOption = mobileDatalist.querySelector(
          `option[value='${e.target.value}']`
        );

        if (selectedOption) {
          console.log("submit form.");
          dispatch(setSearchTerm(e.target.value));
          push(
            "/searchAction/?appendedinputbuttons=" +
              encodeURIComponent(e.target.value)
          );
          inputFieldRef.current.blur();
        }
      } else {
        const datalist = document.getElementById("defiantList");

        // console.log(e.target.value);
        const selectedOption = datalist.querySelector(
          `option[value='${e.target.value}']`
        );
        // const mobileSelectedOption = mobileDatalist.querySelector(`option[value='${e.target.value}']`);
        // console.log(selectedOption);
        if (selectedOption) {
          console.log("submit form.");
          dispatch(setSearchTerm(e.target.value));
          push(
            "/searchAction/?appendedinputbuttons=" +
              encodeURIComponent(e.target.value)
          );
          inputFieldRef.current.blur();
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSearchClick = (inputType = "desktop") => {
    let searchValue = document.getElementById("searchString").value;
    let searchValueMobile = document.getElementById("searchStringMobile").value;
    console.log(searchValue, inputType);
    if (inputType === "mobile") {
      searchValue = searchValueMobile;
    }
    if (searchValue.length >= 3) {
      console.log(searchValue);
      dispatch(setSearchTerm(searchValue));
      push(
        "/searchAction/?appendedinputbuttons=" + encodeURIComponent(searchValue)
      );
      inputFieldRef.current.blur();
    }
  };

  const keyupSearchHandler = (e, setWithinSearchVal, setSynArray, isMobile) => {
    let searchInputValue = e.target.value.toLowerCase().trim();

    if (searchInputValue.length !== 0) keypressSearchHandler(e);

    // console.log(e.target.value);
    const datalist = document.getElementById("defiantList");
    const mobileDatalist = document.getElementById("mobileDefiantList");
    if (searchInputValue.length >= 3 && datalist.innerHTML === "") {
      datalist.innerHTML = "";
      mobileDatalist.innerHTML = "";
      // console.log("->",searchInputValue);

      // const treatedWords = event.target.value.split(" ").map((word)=>{
      //   return `contains(., "${word}")`;
      // });
      // const batchWords = `${treatedWords.join(' and ')}`;

      let results = defiant.search(
        tgg,
        `//*[contains(., "${searchInputValue}")]/text()`
      );
      results = results.flatMap((result) => result.split(", "));
      // console.log(results);
      results.sort((a, b) => {
        const aIndex = a.toLowerCase().indexOf(searchInputValue);
        const bIndex = b.toLowerCase().indexOf(searchInputValue);

        if (aIndex === -1 && bIndex === -1) {
          return a.localeCompare(b);
        } else if (aIndex === -1) {
          return 1;
        } else if (bIndex === -1) {
          return -1;
        } else {
          return aIndex - bIndex;
        }
      });
      results = [...new Set(results)];

      for (let resultCount = 0; resultCount < results.length; resultCount++) {
        const option = document.createElement("option");
        // const regex = new RegExp(event.target.value, "gi");
        // const higlightedText = results[resultCount].replace(regex, (match) => `<strong>${match}</strong>`);
        option.value = results[resultCount]
          .replace(/,|:|\(|\)|\-|\+/g, " ")
          .replace(/   /g, " ")
          .replace(/  /g, " ");

        if (isMobile) {
          mobileDatalist.appendChild(option);
        } else {
          datalist.appendChild(option);
        }
      }
    } else if (searchInputValue.length < 3) {
      // console.log("Enter search terms(s)",searchInputValue);
      datalist.innerHTML = "";
      mobileDatalist.innerHTML = "";
      // const option = document.createElement("option");
      // option.value = "";
      // datalist.appendChild(option);
    }
  };

  const goBack = () => {
    history.goBack();
  };

  const openModal = (e) => {
    e.preventDefault();
    dispatch(setOpenModalFlag(true));
  };

  const closeModal = () => {
    dispatch(setOpenModalFlag(false));
  };

  const handleLoginClick = () => {
    let tglogin = document.getElementById("tglogin");
    if (tglogin.classList.contains("in")) {
      tglogin.classList.toggle("in");
      setTimeout(() => {
        tglogin.style.display = "none";
      }, 100);
    } else {
      tglogin.style.display = "block";
      setTimeout(() => {
        tglogin.classList.toggle("in");
      }, 100);
    }
  };

  const showHeader = !(window.Microsoft || false);
  let style = { display: "none" };
  if (showHeader) {
    style = {};
  }

  const handleImportClick = () => {
    push("/import");
  };

  return (
    <div className="navbar navbar-static-top" id="dart-nav" role="navigation">
      <div className="container container-center">
        <div className="navbar-header">
          <div className="mobile-navbar">
            <div>
              <table>
                <tbody>
                  <tr>
                    <th>
                      <a title="Back" href="#" onClick={goBack}>
                        <i
                          style={{ fontSize: "40px", color: "#5d5a58" }}
                          className="fa"
                        >
                          &#xf104;
                        </i>
                      </a>
                    </th>
                    <th>
                      <NavLink
                        to="#"
                        title="Menu"
                        className={`mobile-menu-button ${mobileMenuClass}`}
                        onClick={toggleMobileMenu}
                      >
                        <i
                          style={{ fontSize: "24px", color: "#5d5a58" }}
                          className={`fa ${faClass}`}
                        ></i>
                      </NavLink>
                    </th>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="mobile-logo">
              <HashLink
                to="/etgAccess/"
                title="Therapeutic Guidelines"
                className="homelink"
              >
                <svg>
                  <image
                    xlinkHref="/TherapeuticGuidelines_Original_Logo.svg"
                    src="./assets/images/tglLogo.png"
                    alt="Therapeutic Guidelines"
                  ></image>
                </svg>
              </HashLink>
            </div>
            <div
              className={`mobile-search ${mobileSearchClass}`}
              onClick={() => mobileSearchToggle()}
            >
              <NavLink
                to="#"
                title="Search"
                onClick={(e) => {
                  e.preventDefault();
                }}
              >
                <i
                  style={{ fontSize: "24px" }}
                  className={`fa ${searchFaClass}`}
                ></i>
              </NavLink>
            </div>
          </div>
          <div className="desktop-navbar">
            <div className="visible-xs-block col-xs-9 navbar-brand-xs">
              <div
                className="xrm-editable-html xrm-attribute"
                data-languagecontext="English"
              >
                <div className="xrm-attribute-value">
                  <p>
                    <a
                      href="/"
                      title="Therapeutic Guidelines"
                      className="homelink"
                    >
                      <svg width="127" height="39">
                        <image
                          xlinkHref="/TherapeuticGuidelines_Original_Logo.svg"
                          src="./assets/images/tglLogo.png"
                          width="127"
                          height="39"
                          alt="Therapeutic Guidelines"
                        ></image>
                      </svg>
                    </a>
                  </p>
                </div>
              </div>
            </div>
            <div className="visible-sm-block visible-md-block visible-lg-block navbar-brand">
              <div
                className="xrm-editable-html xrm-attribute"
                data-languagecontext="English"
              >
                <div
                  className="xrm-attribute-value dart-mobile-logo"
                  style={style}
                >
                  <p>
                    <a
                      href="/"
                      title="Therapeutic Guidelines"
                      className="homelink"
                    >
                      <svg width="127" height="39">
                        <image
                          xlinkHref="/TherapeuticGuidelines_Original_Logo.svg"
                          src="./assets/images/tglLogo.png"
                          width="127"
                          height="39"
                          alt="Therapeutic Guidelines"
                        ></image>
                      </svg>
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="navbar-right loginsubbutton visible-lg-block"
          style={{ paddingLeft: "0" }}
        >
          <div className="btn-group" style={{ display: "none" }}>
            <a
              href="#"
              className="btn btn-info btn-block institutionBoxIns"
              style={{ background: "#415b63", textDecoration: "underline" }}
            >
              Therapeutic Guidelines
            </a>
          </div>
        </div>
      </div>
      <div className="container tg-mobile-menu">
        {!window.Microsoft && (
          <div
            id="navbar-right-section"
            style={{ display: "flex", alignItems: "center" }}
          >
            {version && (
              <span
                className="import-indicate"
                style={{
                  display: versionByImport ? "inline" : "none",
                  textAlign: "right",
                }}
              >
                Current Version: {version} &nbsp;&nbsp;
                <span
                  className="import-indicate-text"
                  style={{
                    display: "block",
                    lineHeight: "1",
                    margin: "0",
                    padding: "0",
                    color: "#e57373",
                  }}
                >
                  After refreshing, go to{" "}
                  <button
                    onClick={handleImportClick}
                    style={{
                      backgroundColor: "transparent",
                      border: "none",
                      color: "#e57373",
                      cursor: "pointer",
                      textDecoration: "underline",
                      padding: "0",
                    }}
                  >
                    Import
                  </button>{" "}
                  to select the version again.&nbsp;&nbsp;
                </span>
              </span>
            )}

            <Profile authID={authID} handleLoginClick={handleLoginClick} />
            {(!authID.u || authID.u == " ") &&
              (!authID.i || authID.i === " ") && (
                <LoginButton handleLoginClick={handleLoginClick} />
              )}
          </div>
        )}
        <div
          id="navbar"
          className="navbar-collapse"
          style={{ maxHeight: "734px" }}
        >
          <div
            className="navbar-right menu-bar float-lg-none"
            data-weblinks-maxdepth="2"
          >
            <div className="container dart">
              <div className="row">
                <div className="col-xs-12 col-sm-9 col-sm-offset-0 col-md-9 col-md-offset-0 col-lg-7 col-lg-offset-2">
                  <ul className="nav navbar-nav weblinks" role="menubar">
                    <li
                      role="none"
                      className="visible-xs-block visible-sm-block visible-md-block"
                    >
                      <div
                        className="navbar-right loginsubbutton"
                        style={{ display: "none" }}
                      >
                        <div className="btn-group">
                          <a
                            href="#"
                            className="btn btn-info btn-block"
                            style={{ background: "#009965" }}
                          >
                            Access by OrgName
                          </a>
                        </div>
                      </div>
                    </li>
                    <li
                      role="none"
                      className={`weblink dropdown ${guidelineOpenClass}`}
                      id="guidelinesDropdown"
                    >
                      <NavLink
                        to="#"
                        role="menuitem"
                        aria-label="Guidelines"
                        className="dropdown-toggle dropbtn"
                        data-toggle="dropdown"
                        title="Guidelines"
                        aria-expanded="false"
                        aria-haspopup="true"
                        onClick={guidelinesDropdown}
                      >
                        Guidelines
                        <span className="caret"></span>
                      </NavLink>
                      <ul className="dropdown-menu" role="menu">
                        <li role="none">
                          <a
                            role="menuitem"
                            aria-label="Addiction Medicine"
                            href="#"
                            title="Addiction Medicine"
                            onClick={() => openLinks("Addiction Medicine")}
                          >
                            Addiction
                          </a>
                        </li>
                        <li role="none">
                          <a
                            role="menuitem"
                            aria-label="Antibiotic"
                            href="#"
                            title="Antibiotic"
                            onClick={() => openLinks("Antibiotic")}
                          >
                            Antibiotic
                          </a>
                        </li>
                        <li role="none">
                          <a
                            role="menuitem"
                            aria-label="Bone and Metabolism"
                            href="#"
                            title="Bone and Metabolism"
                            onClick={() => openLinks("Bone and Metabolism")}
                          >
                            Bone and Metabolism
                          </a>
                        </li>
                        <li role="none">
                          <a
                            role="menuitem"
                            aria-label="Cardiovascular"
                            href="#"
                            title="Cardiovascular"
                            onClick={() => openLinks("Cardiovascular")}
                          >
                            Cardiovascular
                          </a>
                        </li>
                        <li role="none">
                          <a
                            role="menuitem"
                            aria-label="Dermatology"
                            href="#"
                            title="Dermatology"
                            onClick={() => openLinks("Dermatology")}
                          >
                            Dermatology
                          </a>
                        </li>
                        <li role="none">
                          <a
                            role="menuitem"
                            aria-label="Developmental Disability"
                            href="#"
                            title="Developmental Disability"
                            onClick={() =>
                              openLinks("Developmental Disability")
                            }
                          >
                            Developmental Disability
                          </a>
                        </li>
                        <li role="none">
                          <a
                            role="menuitem"
                            aria-label="Diabetes"
                            href="#"
                            title="Diabetes"
                            onClick={() => openLinks("Diabetes")}
                          >
                            Diabetes
                          </a>
                        </li>
                        <li role="none">
                          <a
                            role="menuitem"
                            aria-label="Fatigue"
                            href="#"
                            title="Fatigue"
                            onClick={() => openLinks("Fatigue")}
                          >
                            Fatigue
                          </a>
                        </li>
                        <li role="none">
                          <a
                            role="menuitem"
                            aria-label="Gastrointestinal"
                            href="#"
                            title="Gastrointestinal"
                            onClick={() => openLinks("Gastrointestinal")}
                          >
                            Gastrointestinal
                          </a>
                        </li>
                        <li role="none">
                          <a
                            role="menuitem"
                            aria-label="Liver Disorders"
                            href="#"
                            title="Liver Disorders"
                            onClick={() => openLinks("Liver Disorders")}
                          >
                            Liver Disorders
                          </a>
                        </li>
                        <li role="none">
                          <a
                            role="menuitem"
                            aria-label="Neurology"
                            href="#"
                            title="Neurology"
                            onClick={() => openLinks("Neurology")}
                          >
                            Neurology
                          </a>
                        </li>
                        <li role="none">
                          <a
                            role="menuitem"
                            aria-label="Oral and Dental"
                            href="#"
                            title="Oral and Dental"
                            onClick={() => openLinks("Oral and Dental")}
                          >
                            Oral and Dental
                          </a>
                        </li>
                        <li role="none">
                          <a
                            role="menuitem"
                            aria-label="Pain and Analgesia"
                            href="#"
                            title="Pain and Analgesia"
                            onClick={() => openLinks("Pain and Analgesia")}
                          >
                            Pain and Analgesia
                          </a>
                        </li>
                        <li role="none">
                          <a
                            role="menuitem"
                            aria-label="Palliative Care"
                            href="#"
                            title="Palliative Care"
                            onClick={() => openLinks("Palliative Care")}
                          >
                            Palliative Care
                          </a>
                        </li>
                        <li role="none">
                          <a
                            role="menuitem"
                            aria-label="Psychotropic"
                            href="#"
                            title="Psychotropic"
                            onClick={() => openLinks("Psychotropic")}
                          >
                            Psychotropic
                          </a>
                        </li>
                        <li role="none">
                          <a
                            role="menuitem"
                            aria-label="Respiratory"
                            href="#"
                            title="Respiratory"
                            onClick={() => openLinks("Respiratory")}
                          >
                            Respiratory
                          </a>
                        </li>
                        <li role="none">
                          <a
                            role="menuitem"
                            aria-label="Rheumatology"
                            href="#"
                            title="Rheumatology"
                            onClick={() => openLinks("Rheumatology")}
                          >
                            Rheumatology
                          </a>
                        </li>
                        <li role="none">
                          <a
                            role="menuitem"
                            aria-label="Sexual and Reproductive Health"
                            href="#"
                            title="Sexual and Reproductive Health"
                            onClick={() =>
                              openLinks("Sexual and Reproductive Health")
                            }
                          >
                            Sexual and Reproductive Health
                          </a>
                        </li>
                        <li role="none">
                          <a
                            role="menuitem"
                            aria-label="Toxicology and Toxinology"
                            href="#"
                            title="Toxicology and Toxinology"
                            onClick={() =>
                              openLinks("Toxicology and Toxinology")
                            }
                          >
                            Toxicology and Toxinology
                          </a>
                        </li>
                        <li role="none">
                          <a
                            role="menuitem"
                            aria-label="Ulcer and Wound Management"
                            href="#"
                            title="Ulcer and Wound Management"
                            onClick={() =>
                              openLinks("Ulcer and Wound Management")
                            }
                          >
                            Ulcer and Wound Management
                          </a>
                        </li>
                        <li role="none">
                          <a
                            role="menuitem"
                            aria-label="Wilderness Medicine"
                            href="#"
                            title="Wilderness Medicine"
                            onClick={() => openLinks("Wilderness Medicine")}
                          >
                            Wilderness Medicine
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li role="none" className="weblink ">
                      <a
                        role="menuitem"
                        data-toggle="modal"
                        href="#drugIndexModal"
                        aria-label="Drugs"
                        title="Drugs"
                      >
                        Drugs
                      </a>
                    </li>
                    <li
                      role="none"
                      className={`weblink dropdown ${quicklinksOpenClass}`}
                      id="quicklinksDropdown"
                    >
                      <NavLink
                        to="#"
                        role="menuitem"
                        aria-label="Quick links"
                        className="dropdown-toggle dropbtn"
                        data-toggle="dropdown"
                        title="Quick links"
                        aria-expanded="false"
                        aria-haspopup="true"
                        onClick={quicklinksDropdown}
                      >
                        Quick links
                        <span className="caret"></span>
                      </NavLink>
                      <ul className="dropdown-menu" role="menu">
                        <li role="none">
                          <a
                            role="menuitem"
                            aria-label="Antibiotic prescribing in primary care - summary table"
                            href="https://ccmsfiles.tg.org.au/s3/PDFs/GPSummary_v15.pdf"
                            target="_blank"
                            rel="noopener noreferrer"
                            title="Antibiotic prescribing in primary care - summary table"
                          >
                            Antibiotic prescribing in primary care - summary
                            table
                          </a>
                        </li>
                        <li role="none">
                          <a
                            role="menuitem"
                            aria-label="Surgical prophylaxis - summary tables"
                            href="https://ccmsfiles.tg.org.au/s3/PDFs/ABG16-Surgical-antibiotic-prophylaxis-Collated-table-v4.pdf?type=Surgical%20prophylaxis%20-%20summary%20tables"
                            target="_blank"
                            rel="noopener noreferrer"
                            title="Surgical prophylaxis - summary tables"
                          >
                            Surgical prophylaxis - summary tables
                          </a>
                        </li>
                        <li role="none">
                          <HashLink
                            role="menuitem"
                            aria-label="Patient information and useful PDFs"
                            to="/patientInfo/"
                            title="Patient information and useful PDFs"
                          >
                            Patient information and useful PDFs
                          </HashLink>
                        </li>
                        <li role="none">
                          <HashLink
                            role="menuitem"
                            aria-label="Pregnancy and breastfeeding"
                            to="/viewTopic?guidelinePage=quicklinks&etgAccess=true"
                          >
                            Pregnancy and breastfeeding
                          </HashLink>
                        </li>
                        {(authID.i || authID.u) && (
                          <li role="none">
                            <HashLink
                              role="menuitem"
                              aria-label="Calculators"
                              to="/calculator/#bsacalc"
                              title="Calculators"
                            >
                              Calculators
                            </HashLink>
                          </li>
                        )}
                        <li role="none">
                          <HashLink
                            role="menuitem"
                            aria-label="Tables and figures"
                            to="/tables-and-figures/"
                            title="Tables and figures"
                          >
                            Tables and figures
                          </HashLink>
                        </li>
                      </ul>
                    </li>
                    <li role="none" className="weblink ">
                      <NavLink
                        to="#"
                        role="menuitem"
                        data-toggle="modal"
                        aria-label="Favourites"
                        data-target="#myFavouritesModal"
                        onClick={openModal}
                      >
                        Favourites
                      </NavLink>
                    </li>
                    <li role="none" className="weblink ">
                      <HashLink
                        role="menuitem"
                        aria-label="Saved Searches"
                        to="/searchAction/"
                        title="Saved Searches"
                      >
                        Saved Searches
                      </HashLink>
                    </li>
                  </ul>
                </div>
                <div className="col-xs-12 col-sm-3 col-md-3">
                  <div id="searchForm" className="searchMobileHidden">
                    <div className="input-group search-widget">
                      <div className="form-group">
                        <input
                          type="text"
                          name="appendedinputbuttons"
                          id="searchString"
                          data-testid="searchString"
                          className="form-control"
                          placeholder="Search"
                          autoComplete="on"
                          list="defiantList"
                          onKeyUp={(e) =>
                            keyupSearchHandler(
                              e,
                              setWithinSearchVal,
                              setSynArray
                            )
                          }
                          onMouseUp={(e) =>
                            keyupSearchHandler(
                              e,
                              setWithinSearchVal,
                              setSynArray
                            )
                          }
                          onChange={(e) => handleSearchChange(e, dispatch)}
                          value={searchIndex}
                          ref={inputFieldRef}
                        />
                        <datalist id="defiantList">
                          <option value="" />
                        </datalist>
                      </div>
                      <span className="input-group-btn">
                        <a
                          data-testid="global-search-button"
                          className="btn nopadding btnsearchHeader"
                          onClick={() => handleSearchClick()}
                        >
                          <i className="flaticon-magnifying-glass34"></i>
                        </a>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {openModalFlag && (
        <FavouriteModal openModalFlag={openModalFlag} closeModal={closeModal} />
      )}
      <div className="container tg-mobile-search">
        <div
          id="navbar"
          className="navbar-collapse"
          style={{ maxHeight: "734px" }}
        >
          <div
            className="navbar-right menu-bar float-lg-none "
            data-weblinks-maxdepth="2"
          >
            <ul className="nav navbar-nav weblinks" role="menubar">
              <li role="none">
                <div id="searchForm" className="searchMobileVisible">
                  <div className="input-group search-widget">
                    <div className="form-group">
                      <input
                        type="text"
                        name="appendedinputbuttons"
                        id="searchStringMobile"
                        className="form-control"
                        placeholder="Search"
                        autoComplete="off"
                        list="mobileDefiantList"
                        onKeyUp={(e) =>
                          keyupSearchHandler(
                            e,
                            setWithinSearchVal,
                            setSynArray,
                            true
                          )
                        }
                        onMouseUp={(e) =>
                          keyupSearchHandler(
                            e,
                            setWithinSearchVal,
                            setSynArray,
                            true
                          )
                        }
                        onChange={(e) => handleSearchChange(e, dispatch, true)}
                        value={searchIndex}
                      />
                      <datalist id="mobileDefiantList">
                        <option value="" />
                      </datalist>
                    </div>
                    <span className="input-group-btn">
                      <a
                        className="btn nopadding btnsearchHeader"
                        onClick={() => handleSearchClick("mobile")}
                      >
                        <i className="flaticon-magnifying-glass34"></i>
                      </a>
                    </span>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <DrugIndexModal push={push} />
    </div>
  );
};

NavBar.propTypes = {
  searchIndex: PropTypes.number,
  setSearchTerm: PropTypes.func,
  push: PropTypes.func.isRequired,
  setWithinSearchVal: PropTypes.func,
  setSynArray: PropTypes.func,
  isDXP: PropTypes.bool,
};

export default NavBar;
